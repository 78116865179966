.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #333;
  border-radius: 50%;
  opacity: 0;
  animation: dot-flashing 2s infinite;
}

@keyframes dot-flashing {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dot:nth-child(1) {
  animation-name: dot-flashing-always;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  opacity: 1;
}

@keyframes dot-flashing-always {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.dot:nth-child(2) {
  animation-delay: 0.4s;
}

.dot:nth-child(3) {
  animation-delay: 0.8s;
}
