@import 'assets/styles/variables';

.SearchFormLanguageSwitcher {
  @include dropdown;
  .dropdown-trigger > .button {
    border-radius: 0 100px 100px 0;
  }
}

.button {
  border-left: none !important;
}
