@import 'assets/styles/variables';

.PageSingleSREntry {
  .single-title {
    @include single-entry-title;
  }
  .meta-data {
    @include single-meta-data;
  }
  .inline-image {
    @include single-entry-inline-image;
  }
  .block-image {
    @include single-entry-block-image;
  }
  .content {
    @include single-entry-content;
  }
}
