@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin single-entry-title {
  font-size: 32px;
  line-height: 1.15em;
  margin-bottom: 5px;
}

@mixin single-meta-data {
  font-size: 17px;
  margin: 4px 0 15px;
  .source-link {
    margin-left: 12px;
    .icon {
      font-size: 13px;
      margin: 0.5px 0 0 2px;
    }
  }
}

@mixin single-entry-inline-image {
  float: right;
  max-width: 38%;
  margin: 3px 0 5px 15px;
  padding: 5px;
  line-height: 0;
  border: 1px solid $color-border;
  box-shadow: $shadow-navigation-buttons;
  @include touch {
    display: none;
  }
}

@mixin single-entry-block-image {
  display: none;
  @include touch {
    display: block;
  }
}

@mixin single-entry-article-image {
  @include single-entry-inline-image;
  display: inline-block;
  float: none;
  max-width: none;
  margin: 0;
  @include touch {
    display: inline-block;
    margin-top: 20px !important;
  }
}

@mixin single-entry-content($max-width: 900px) {
  max-width: $max-width;
  white-space: pre-line;
  p {
    font-size: 1.1em;
  }

  h2,
  h3,
  h4 {
    font-weight: normal;
    margin-bottom: 8px;
  }
  h2 {
    font-size: 24px;
    @include mobile {
      font-size: 20px;
    }
  }
  h3 {
    font-size: 20px;
    @include mobile {
      font-size: 18px;
    }
  }
  h4 {
    font-size: 18px;
  }
}

@mixin single-entry-article-container {
  @include touch {
    display: flex;
    flex-direction: column-reverse;
  }
  .content-part {
    padding-top: 20px;
    &:first-child {
      padding-top: 0;
    }
  }
  p {
    margin-bottom: 1em;
  }
  .table-of-contents {
    position: sticky;
    top: 25px;
    @include touch {
      position: static;
      top: 0;
      margin-top: 14px;
    }
    h3 {
      margin-bottom: 12px;
    }
    .list {
      margin: 0 0 0 10px;
      list-style-position: inside;
    }
  }
}

@mixin source-criticism-colors {
  background-color: $color-source-criticism-news;
  color: $color-on-source-criticism-news;
  &.source-criticism-sveriges-radio {
    background-color: $color-source-criticism-sveriges-radio;
    color: $color-on-source-criticism-sveriges-radio;
  }
  &.source-criticism-encyclopedia {
    background-color: $color-source-criticism-encyclopedia;
    color: $color-on-source-criticism-encyclopedia;
  }
  &.source-criticism-dictionary {
    background-color: $color-source-criticism-dictionary;
    color: $color-on-source-criticism-dictionary;
  }
  &.source-criticism-russian-news {
    background-color: $color-source-criticism-russian-news;
    color: $color-on-source-criticism-russian-news;
  }
}

@mixin divider($color: $color-border) {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  > span {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: $color;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }

    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

@mixin list-item {
  overflow: hidden;
  border-radius: 50px;
  background-color: $color-bg-card;
  border: 1px solid $color-border;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 40px;
  transform: translateY(-50%) scale(1);
  transition: all 0.2s ease-in-out;
  color: $color-text;
  &:hover {
    transform: translateY(-49%) scale(1.01);
    box-shadow: rgba(50, 50, 93, 0.2) 0px 13px 27px -5px, rgba(0, 0, 0, 0.25) 0px 8px 16px -8px;
  }
  @include touch {
    padding: 40px 30px;
  }
  @include mobile {
    transform: translateY(25px) scale(1);
    &:hover {
      transform: translateY(27px) scale(1.01);
    }
  }
  .title {
    font-size: 26px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
  .text {
    line-height: 1.25em;
  }
  .item-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 20px;
    &.favorite {
      color: $color-favorite;
    }
  }
}

@mixin dropdown {
  .dropdown-content {
    padding: 0;
  }
  .dropdown-trigger > .button {
    transition: all 0.2s ease;
    &:hover {
      border-color: $input-focus-border-color;
    }
    &:focus {
      box-shadow: none;
      border-color: $input-focus-border-color;
    }
    img.icon {
      margin-left: -6px;
      margin-right: 0;
    }
  }
  .dropdown-menu {
    min-width: auto;
    .dropdown-item {
      padding: 0.6rem calc(24px + 1rem) 0.6rem 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: $color-bg-card;
      transition: all 0.2s;
      &:hover {
        background-color: darken($color-bg-card, 3%);
      }
      p {
        white-space: nowrap;
        font-size: 15px;
      }
      img {
        margin-right: 10px;
        width: 24px;
      }
    }
  }
  .dropdown-divider {
    margin: 0;
  }
}
