.form {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  padding: 26px;
  border-radius: 8px;
  max-height: calc(100vh - 175px);
  overflow-y: auto;

  .MuiButton-root {
    margin-top: auto;
  }

  .MuiTextField-root {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    .MuiOutlinedInput-root {
      fieldset {
        border: none;
      }
      &:hover fieldset {
        border: none;
      }
      &.Mui-focused fieldset {
        border: none;
      }
    }
  }

  .form-label {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .field-container {
    position: relative;
    margin-bottom: 20px;
  }

  .form-textarea,
  .form-input {
    width: 100%;
    border: 1px solid #dedede;
    border-radius: 12px;
    font-size: 16px;
    outline: none;
    background-color: #f9f9f9;
    resize: none;
  }

  .form-input {
    min-height: 50px;
  }
  .form-textarea {
    min-height: 100px;
  }

  .floating-label {
    position: absolute;
    left: 10px;
    top: 10px;
    transition: transform 0.2s ease-out, color 0.2s ease-out;
    transform-origin: top left;
    color: #ccc;
    pointer-events: none;
  }

  button {
    margin-top: auto;
    background-color: #e0e0e0;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #d6d6d6;
    }

    &:active {
      background-color: #cbcbcb;
    }
  }
}

.form::-webkit-scrollbar {
  width: 8px; /* Making the scrollbar thin */
}

.form::-webkit-scrollbar-track {
  background: transparent; /* Optional: removes default styling */
}

.form::-webkit-scrollbar-thumb {
  background-color: #39b0bb; /* Your desired color */
  border-radius: 10px;
}

.generate-image-input {
  max-height: 40%;
}

.warning-message {
  position: fixed;
  top: 25rem;
  width: 19.5rem;
  padding: 0;

  .message-body {
    margin-top: 0rem;
  }
}
