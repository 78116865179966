@import 'assets/styles/variables';

.SourceCriticism {
  @include source-criticism-colors;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 6px;
  border-radius: 8px;
  .icon {
    flex-shrink: 0;
    margin: 0 7px 0 3px;
    font-size: 18px;
  }
  .text {
    display: block;
    line-height: 1.3em;
    margin: 3px 0 5px 0;
    font-size: 16px;
  }
}
