#gpt-model-selector {
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping */
}

#gpt-model-selector ul {
  border: 1px solid #dbdbdb;
  border-radius: 50px;
  padding: 2px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping */
  width: 100%; /* Ensure the ul takes the full width */
}

#gpt-model-selector li {
  margin: 0 4px;
  padding: 2px 0;
  width: 142px;
  text-align: center;
  flex: 0 1 auto; /* Allow flexible shrinking and wrapping */
}

#gpt-model-selector li a {
  border-radius: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
  background: #f9f9f9;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#gpt-model-selector li a span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#gpt-model-selector li.is-active a {
  border-color: #dbdbdb;
  background: #edf1fc;
}
