html {
  background-color: $color-bg-main;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.capitalize-first-letter {
  &::first-letter {
    text-transform: capitalize;
  }
}

body.no-results .hide-when-no-results {
  display: none !important;
}
