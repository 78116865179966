@import 'assets/styles/variables';

.PageSingleEncyclopediaEntry {
  .single-title {
    @include single-entry-title;
  }
  .content {
    @include single-entry-content;
  }
  .article-image {
    @include single-entry-article-image;
  }
  .article-container {
    @include single-entry-article-container;
  }
  .tabs.article-toggle {
    margin: 8px 0 20px;
  }
  .references {
    li {
      font-size: 16px;
    }
  }
}
