@import 'assets/styles/variables';

.vertical-line {
  border-left: 1px solid $color-border;
  height: 100%;
  top: 0;
  position: fixed;
  left: 340px;
  z-index: -1;
}

.info-box {
  padding-left: 40px;
  padding-top: 20px;
  padding-right: 40px;
  width: 354px;
  font-size: 16px;
}

.description {
  font-size: 16px;
}

.chat-box {
  max-width: 100vw;
  display: grid;
  height: calc(100vh - 120px);
}

.config-box {
  padding-left: 72vw;
  width: 100%;
  display: grid;
}

.gpt-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 10px;
}

.configs {
  max-height: 500px;
  width: 100%;
  max-width: 1280px;
  margin-top: -490px;
  padding-bottom: 10px;
  padding-right: 88px;
  padding-left: 50px;
  z-index: 1;
}

.melded-box {
  background-color: #f5f5f5; /* change this to match your box color */
  border-radius: 4px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
}

.melded-box:before {
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  z-index: -1;
  background: linear-gradient(
    white,
    #f5f5f5
  ); /* replace "white" with your background color and "#f5f5f5" with your box color */
  border-radius: 4px;
}

.melded-box:hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.example-title {
  font-size: 26px;
  text-align: left;
  padding-bottom: 10px;
}

.small-chat-example {
  position: relative;
  border-radius: 100px;
  border: 1px solid $color-border;
  width: 260px;
  height: 40px;
  background-color: #ffffff;
}

.small-chat-example-1 {
  margin-top: 25px;
}

.small-chat-example-2 {
  margin-top: 15px;
}

.small-chat-example-3 {
  margin-top: 40px;
  margin-left: 48px;
  width: 164px;
  height: 40px;
  background-color: #506a93;
  cursor: pointer;
}

.example-question {
  position: absolute;
  padding-left: 15px;
  padding-top: 7px;
  font-size: 15.5px;
}

.example-question-3 {
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}

.image {
  width: 60%;
  max-width: 100%;
  padding-top: 40px;
  padding-bottom: 10px;
  //padding-left: 100px;
  margin-left: 40px;
}

.gdpr-text {
  display: inline-block;
  padding-left: 240px;
  //padding-top: 10px;
  padding-bottom: 90px;
}

.Gdpr-dont-show {
  display: none;
}

.cancel-prompt-button-wrapper {
  position: absolute;
  left: 45%;
  top: -50px;
  transform: translateX(-50%);
  z-index: 10;
}

.input-field {
  height: 150px;
  padding-left: 5vw;
  padding-right: 5vw;
}

.text-input {
  width: 64vw;
  height: 58px;
  border-radius: 8px;
  border: 1px solid $color-border;
  outline: none;
  background-color: $color-chat-box;
  transition: border-color 0.1s ease-out;
  font-size: 16px;
}

.label-text {
  position: absolute;
  left: 93px;
  padding-left: 5px;
  top: 15px;
  font-size: 12px;
  transition: all 0.1s ease-out;
  transform-origin: top left;
  padding: 0 2px;
  z-index: -1;
  opacity: 0;
}

.text-input:focus {
  z-index: 1;
  border: 1px solid $color-border;
}

.text-input:focus + .label-text {
  padding-top: 15px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 10px;
  opacity: 1;
  z-index: 1;
  position: absolute;
  background-color: #fcfcfc;
}

.text-input:focus + .label-text,
.filled {
  top: -12px;
  color: $color-text;
  font-size: 14px;
}

.text-input::placeholder {
  font-size: 16px;
  color: $color-text;
  opacity: 1;
}

.text-input:focus::placeholder {
  opacity: 0;
  animation-delay: 0.15s;
}

.conversation {
  height: calc(100vh - 180px - 120px);
  width: 100%;
  max-width: 74vw;
  margin-left: 3vw;
  margin-right: 13vw;
  margin-top: 10px;
  margin-bottom: 0px;
  z-index: 1;
}

.question-container {
  display: flex;
  justify-content: flex-end;
}

.chatGptPic {
  padding-top: 6px;
}

.answer-container-flex {
  display: flex;
  align-items: flex-start;
}

.messages {
  padding-bottom: 20px;
  &--received {
    .message {
      background-color: #f4f4f4;
      font-size: 16px;
      border-radius: 20px;
    }
  }
  &--sent {
    .message {
      background-color: #f0f6f2;
      font-size: 16px;
      border-radius: 14px;
    }
  }
}

.message {
  display: inline-block;
  margin-bottom: 2px;
  clear: both;
  padding: 7px 13px;
  font-size: 12px;
  border-radius: 15px;
  line-height: 1.4;
  margin-left: 10px;
}

.answer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding-bottom: 0px;
}

.help-button-container {
  margin-top: -30px;
  margin-left: 50px;
  padding-left: 0px;
  padding-right: 15px;
  margin-bottom: 5px;
}

.simplify-button {
  padding-top: 0px;

  border-radius: 25px; /* This will make the corners rounded */
  background-color: #f4f4f4; /* Semi-transparent white background */
  color: black; /* Text color */
  transition: all 0.3s ease-in-out; /* This makes the change on hover animate nicely */
  cursor: pointer; /* Changes the cursor to a hand when over the button */
  outline: none;
}

simplify-button:hover {
  background-color: rgba(255, 255, 255, 1); /* Fully opaque white background on hover */
}

simplify-button:active {
  transform: scale(0.98); /* Makes the button slightly smaller when clicked */
}

.gif {
  width: 50px;
  height: 20px;
  padding-top: 3px;
}

.right-padding {
  padding-right: 25px;
}

.content-container {
  //overflow-x: hidden;
  max-width: 100vw;
  height: calc(100vh - 120px);
}

@media (max-width: 700px) {
  .content-container {
    width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .info-box {
    max-width: fit-content;
  }

  .description {
    display: none;
  }

  .vertical-line {
    max-width: fit-content;
  }

  .label-text {
    display: none;
  }

  .chat-box {
    width: 95%;
    max-width: 95%;
  }

  .config-box {
    padding-top: 82vh;
    padding-left: 0;
    width: 100%;
  }

  .input-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    box-sizing: border-box;
  }

  .configs {
    padding-left: 15px;
    padding-right: 0px;
    width: 100%;
    max-width: 100%;
  }

  .conversation {
    padding-left: 1vw;
    padding-right: 1vw;
    width: 100%;
    max-width: 100%;
    max-height: 500px;
  }
  .messages {
    padding-bottom: 20px;
    &--received {
      .message {
        max-width: 85vw;
      }
    }
    &--sent {
      .message {
        max-width: 70vw;
      }
    }
  }

  .text-input {
    width: 70vw;
    height: 58px;
    border-radius: 8px;
    border: 1px solid $color-border;
    outline: none;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $color-chat-box;
    transition: border-color 0.1s ease-out;
    font-size: 16px;
  }
}
