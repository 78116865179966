@import 'assets/styles/variables';

.PageSingleDictionaryEntry {
  .MultiLanguageWordSelector {
    margin: 8px 0;
    @include dropdown;
    .current-word {
      padding-left: 21px;
      p {
        margin-left: 10px;
        margin-top: -3px;
      }
    }
  }
  .word-definition {
    max-width: 850px;
    background-color: white;
    border: 1px solid $color-border;
    border-radius: 5px;
    color: $color-text-lighter;
    padding: 20px 0 25px;
    margin-bottom: 20px;
  }

  .divider {
    @include divider($color-border);
  }
}
