.MuiSlider-rail {
  background-color: #dedede !important;
  height: 2px !important;
  border-radius: 2px !important;
}

.MuiSlider-track {
  background-color: #506a93 !important;
  height: 1px !important;
  border-radius: 1px !important;
}

.MuiSlider-thumb {
  background-color: #506a93 !important;
}

.label-font {
  font-family: 'Sofia Pro Soft', sans-serif;
  letter-spacing: 0.16px;
}
