//? Colors
$color-bg-main: #fcfcfc;
$color-bg-header-start: #f4f4f4;
$color-bg-footer: #f4f4f4;
$color-bg-card: #ffffff;
$color-text: #333333;
$color-text-on-dark: #ffffff;
$color-text-lighter: #4d4f5c;
$color-input-placeholder: #808080;
$color-red: #f44336;
$color-red-light: #f5eee5;
$color-green-light: #ebf0e9;
$color-blue-light: #f2f7ff;
$color-favorite: #edc2c2;
$color-beige-light: #f0edf2;
$color-encyclopedia: $color-green-light;
$color-dictionary: $color-red-light;
$color-mediasearch: $color-blue-light;
$color-chatGpt: $color-beige-light;
$color-border: #dedede;
$color-skolon: #40b4bc;
$color-on-skolon: #ffffff;
$color-chat-box: #f9f9f9;
$color-quizMe: $color-chat-box;
$color-disabilityHelp: #f2f7ff;
$color-substituteTeacher: #f5eee5;
$color-lectureinspiration: #f4f4f4;
$color-source-criticism-blue: #add8e6;
$color-on-source-criticism-blue: #222222;

$color-source-criticism-news: $color-source-criticism-blue;
$color-on-source-criticism-news: $color-on-source-criticism-blue;
$color-source-criticism-sveriges-radio: $color-source-criticism-blue;
$color-on-source-criticism-sveriges-radio: $color-on-source-criticism-blue;
$color-source-criticism-encyclopedia: $color-source-criticism-blue;
$color-on-source-criticism-encyclopedia: $color-on-source-criticism-blue;
$color-source-criticism-dictionary: $color-source-criticism-blue;
$color-on-source-criticism-dictionary: $color-on-source-criticism-blue;
$color-source-criticism-russian-news: $color-red;
$color-on-source-criticism-russian-news: $color-text-on-dark;

$custom-colors: (
  'skolon': (
    $color-skolon,
    $color-on-skolon,
  ),
);

//? Other
$shadow-navigation-buttons: #00000029 0 3px 6px;
$shadow-navigation-buttons-hover: #00000029 0 5px 8px;

//? Bulma overrides
$body-color: $color-text;
$family-sans-serif: 'sofia-pro';
$title-family: 'sofia-pro-soft';
$title-weight: 400;
$title-color: $color-text;
$size-1: 3rem;
$size-2: 2.375rem;
$navbar-breakpoint: 0;
$divider-margin-inner-size: 0px;

$input-border-color: $color-border;
$input-focus-border-color: darken($color-border, 15%);
$input-focus-box-shadow-color: none;
$input-color: $color-text;
$input-focus-color: $color-text;
$input-icon-color: $color-text;
$input-icon-active-color: $color-text;

$tabs-link-padding: 6px 40px;
$tabs-toggle-link-active-background-color: $color-encyclopedia;
$tabs-toggle-link-active-color: $color-text;
$tabs-toggle-link-active-border-color: $color-text-lighter;

@import 'mixins';
@import 'bulma/sass/utilities/_all.sass';
