.ai-tips {
  width: 560px;
  height: 560px;
  border-radius: 40px;
}

.modal-header {
  text-align: center;
  font-family: 'Sofia Pro Soft', sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.4px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.label {
  text-align: center;
}
.modal-card-head {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  width: 100%;
}

.modal-divider {
  height: 1px;
  background-color: gray;
  opacity: 0.2;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 25px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
