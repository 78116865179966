@import 'assets/styles/variables';

.SearchSourceTags {
  transform: translateY(-50%);
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  overflow-x: auto;
  @include hide-scrollbar;
  .inner-container {
    margin: auto;
    display: flex;
    align-items: center;
  }
  .source-tag {
    flex-shrink: 0;
    user-select: none;
    border: 1px solid $color-border;
    height: 40px;
    margin: 0 5px;
    background-color: $color-bg-card;
    transition: all 0.2s;
    &.source-Wiktionary .image {
      margin-top: 0px;
    }
    &.multi-search {
      cursor: pointer;
      &:hover {
        opacity: 1;
        border-color: darken($color-border, 15%);
      }
      &.inactive {
        .image,
        .text {
          opacity: 0.5;
        }
      }
      &.active {
        border-color: darken($color-border, 15%);
      }
    }
    .image {
      display: block;
      margin-top: 2px;
      margin-right: 8px;
      width: 24px;
      max-height: 24px;
      transition: all 0.2s;
    }
    .text {
      color: $color-text-lighter;
      font-size: 14px;
      transition: all 0.2s;
    }
  }
}
