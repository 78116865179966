.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: -1;
}

.modal-overlay.active {
  opacity: 1;
  z-index: 999;
}

.sidebar {
  transform: translateX(100%);
  width: 370px;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  background-color: white;
  z-index: 1000;
}

.sidebar.active {
  transform: translateX(0);
}

.title-header {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 28px;
  margin-left: 24px;
  width: 144px;
  height: 32px;
  color: #4d4f5c;
}

.reset-header {
  margin-top: 15px;
  margin-left: 24px;
  height: 16px;
  color: #506a93;
  font-size: 14px;
  letter-spacing: 0.16px;
  cursor: pointer;
}

.config-sliders {
  margin-left: 1.5vw;
  margin-right: 1.5vw;
}

.gpt-model-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.config-voice-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
