@import 'assets/styles/variables';

.PageSingleNewsEntry {
  .single-title {
    @include single-entry-title;
  }
  .meta-data {
    @include single-meta-data;
  }
  .inline-image {
    @include single-entry-inline-image;
  }
  .block-image {
    @include single-entry-block-image;
  }
  .content {
    @include single-entry-content;
    p:first-child {
      font-weight: 600;
      font-size: 19px;
      line-height: 1.3em;
      margin-bottom: 12px;
    }
  }
}
