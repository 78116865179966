.search-feedback-form {
  position: fixed !important;
  bottom: 0;
  right: 0;
  margin: 1em;
  max-width: 400px !important;
  transition: opacity 0.3s;

  &-visible {
    opacity: 1 !important;
    z-index: 15;
  }

  &-hidden {
    opacity: 0 !important;
    z-index: 0;
  }
}
