.mode-selector-container {
  direction: rtl;
  width: 320px;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 15px;
  box-sizing: border-box;
  border-right: 1px solid #fcfcfc;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.mode-selector-container::-webkit-scrollbar {
  width: 8px;
}

.mode-selector-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.mode-selector-container::-webkit-scrollbar-thumb {
  background-color: #39b0bb;
  border-radius: 10px;
  border: 3px solid transparent;
}

.mode-selector-container > div {
  direction: ltr;
}

.mode-selector-container .content-wrapper {
  direction: ltr;
  text-align: left;
}

.mode-button {
  height: 54px;
  display: flex;
  width: 240px;
  padding: 20px;
  background-color: transparent;
  border: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0px;
  border-radius: 20px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;

  margin-left: 20px;
  margin-right: 20px;

  z-index: 1000;

  &:hover {
    background-color: #e6e6e6;
  }

  &.active {
    background-color: #f4f4f4;
  }
}

.mode-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.warning-label {
  margin-top: 20px;
  display: block;
  text-align: center;
  font-size: 13px;
  color: #666;
  text-align: left;
}

.more-info-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #39b0bb;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: darken(#39b0bb, 10%);
  }
}
