@import 'assets/styles/variables';

.SearchForm {
  margin: 0px auto 0;
  max-width: 680px;

  input {
    transition: all 0.2s;
    border-right: none !important;
    &::placeholder {
      color: $color-input-placeholder;
    }
  }
  .icon .loading {
    font-size: 22px;
  }
  @include touch {
    margin: 35px auto 0;
  }
  @include mobile {
    margin: 20px auto 0;
  }
}
