@import 'assets/styles/variables';

.PageStart {
  max-width: 1280px;
  margin: 0 auto;
  transform: translateY(-10vh);
  @include mobile {
    transform: translateY(24px);
    margin-bottom: 48px;
  }

  .columns {
    gap: 30px;
  }

  .item {
    width: 280px;
    height: 280px;
    align-items: center;
    background-color: $color-encyclopedia;
    border-radius: 50px;
    border: 1px solid $color-border;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0.75vw;
    padding: 50px 20px;
    transform: translateY(0) scale(1);
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: translateY(0.5vh) scale(1.0075);
      box-shadow: rgba(50, 50, 93, 0.2) 0px 13px 27px -5px, rgba(0, 0, 0, 0.25) 0px 8px 16px -8px;
    }
    &.dictionary {
      background-color: $color-dictionary;
    }
    &.mediasearch {
      background-color: $color-mediasearch;
    }
    &.quizMe {
      background-color: $color-quizMe;
    }
    &.chatGpt {
      background-color: $color-chatGpt;
    }
    &.disabilityHelp {
      background-color: $color-disabilityHelp;
    }
    &.lectureinspiration {
      background-color: $color-lectureinspiration;
    }
    &.explainSomething {
      background-color: #f9f9f9;
    }
    &.helpWithAssignment {
      background-color: #f5eee5;
    }

    &.substituteTeacherPlanning {
      background-color: #ebf0e9;
    }
    .title {
      margin-bottom: 2.5rem;
    }
    @include touch {
      margin: 0;
      padding: 40px 30px;
    }
  }
}

.section {
  margin-bottom: 30px;
}

.item-img {
  height: 100px;
  width: 100%;
}
