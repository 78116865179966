@import 'assets/styles/variables';

.AppNavigation.navbar {
  position: absolute;
  width: 100%;
  background-color: transparent;
  padding: 22px 30px 0;
  @media screen and (max-width: 1200px) {
    padding: 12px 12px 0;
  }
  .navbar-item {
    padding: 0;
    &.logo img {
      max-height: 36px;
      transform: translateY(2px);
    }
  }
  .icon-button,
  .skolon-menu-button {
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 50%;
    border-color: $color-border;
    box-shadow: $shadow-navigation-buttons;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    border: solid 1px #dedede;
    box-shadow: none;
    &:hover {
      box-shadow: $shadow-navigation-buttons-hover;
      transform: scale(1.06);
    }
    &:focus {
      box-shadow: $shadow-navigation-buttons;
    }
    &.large {
      width: 50px;
      height: 50px;
      padding: 0 10px;
      border-radius: 10px;
      box-shadow: none !important;
      transform: scale(1) !important;
      &:hover {
        border-color: darken($color-border, 10%);
      }
    }
    img {
      width: 24px;
      &.flag {
        width: 20px;
      }
      &.light {
        opacity: 0.75;
      }
    }
  }
  .language-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    display: none;
    &.show {
      display: block;
    }
  }
  .skolon-menu-button {
    margin: 0 0 4px 12px;

    &:hover {
      border-color: darken($color-border, 10%);
      transform: scale(1);
    }
  }
}
