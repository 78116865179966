@import 'assets/styles/variables';

.AppAudioPlayer {
  min-width: 250px;
  z-index: 10;
  background: $color-mediasearch;
  border: 1px solid $color-border;
  border-radius: 8px;
  padding: 10px 20px 15px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: rgba(50, 50, 93, 0.2) 0px 13px 27px -5px, rgba(0, 0, 0, 0.25) 0px 8px 16px -8px;
  .meta {
    line-height: 1.4em;
    margin-bottom: 12px;
    .meta-title {
      display: block;
      width: 250px;
      font-size: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }
    .source-icon {
      width: 14px;
      opacity: 0.75;
      margin-right: 6px;
      transform: translateY(1px);
    }
  }
  .close-icon {
    font-size: 20px;
    margin-right: 0 !important;
  }

  .eq-bar-container {
    margin: 3px 7px 0 -10px;
    .eq-bar {
      transform: scale(1, -1) translate(0, -24px);
      fill: $color-skolon;
    }

    .eq-bar--1 {
      animation-name: short-eq;
      animation-duration: 0.75s;
      animation-iteration-count: infinite;
      animation-delay: 0s;
    }

    .eq-bar--1 {
      animation-name: short-eq;
      animation-duration: 0.75s;
      animation-iteration-count: infinite;
      animation-delay: 0s;
    }

    .eq-bar--2 {
      animation-name: tall-eq;
      animation-duration: 0.75s;
      animation-iteration-count: infinite;
      animation-delay: 0.17s;
    }

    .eq-bar--3 {
      animation-name: short-eq;
      animation-duration: 0.75s;
      animation-iteration-count: infinite;
      animation-delay: 0.34s;
    }

    @keyframes short-eq {
      0% {
        height: 8px;
      }

      50% {
        height: 4px;
      }

      100% {
        height: 8px;
      }
    }

    @keyframes tall-eq {
      0% {
        height: 14px;
      }

      50% {
        height: 5px;
      }

      100% {
        height: 14px;
      }
    }
  }
}
