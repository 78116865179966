.BackButton {
  min-height: 38px;
  position: relative;
  .button-wrap {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: opacity 0.15s;
    &:hover {
      opacity: 1;
    }
    &.hidden {
      opacity: 0;
    }
    img {
      height: 12px;
      margin-right: 6px;
    }
    span {
      margin-top: -2px;
    }
  }
}
