.divider {
  background-color: #dedede;
  width: 1px;
  min-width: 1px;
  height: 100%;
  align-self: stretch;
  position: relative;

  .divider-handle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 45px;
    background-color: #fff;
    border: 1px solid #dedede;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px 0;

    span {
      position: relative;
      width: 100%;
      height: 3px;
      margin: 2px 0;

      &::before,
      &::after {
        content: '';
        display: block;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background-color: #000;
        position: absolute;
      }

      &::before {
        left: 10px;
      }

      &::after {
        right: 10px;
      }
    }
  }
}
