@import 'assets/styles/variables';

.Page404 {
  position: relative;
  max-width: 1280px;
  margin: 40px auto 0;
  text-align: center;

  img {
    max-width: 500px;
    width: 100%;
    margin-top: 10px;
  }
}
