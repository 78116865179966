// Variables
$bg-color: #f8f9fb;
$bg-hover-color: #edeeee;
$primary-color: #3a78f2;
$primary-hover-color: #2e5cb8;
$secondary-color: #e0e0e0;
$secondary-hover-color: #bcbcbc;
$text-color: #333;
$border-radius: 32px;
$transition-duration: 0.3s;
$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
$window-max-width: 370px;
$window-max-height: 90vh;
$window-width: 90%;
$window-height: auto;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin transition($property, $duration: $transition-duration, $ease: ease) {
  transition: #{$property} #{$duration} #{$ease};
}

// Base styles
.file-upload-container {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  text-transform: none;
}

.upload-button-container {
  .upload-button {
    background-color: $bg-color;
    color: $text-color;
    width: 100%;
    padding: 7px 15px;
    margin-bottom: -15px;
    text-align: center;
    font-size: 12px;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    @include transition(background-color);
    text-transform: none;

    &:hover {
      background-color: $bg-hover-color;
    }
  }
}

.modal-overlay.file-upload {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  @include transition(opacity);

  &.active {
    opacity: 1;
  }
}

.sidebar.file-upload {
  position: relative;
  background-color: white;
  width: $window-width;
  max-width: $window-max-width;
  height: $window-height;
  max-height: $window-max-height;
  overflow-y: auto;
  padding: 20px;
  border-radius: $border-radius;
  opacity: 0;
  z-index: 1000;
  @include flex-center;
  flex-direction: column;
  @include transition(height, opacity);
  box-shadow: $box-shadow;

  &.active {
    opacity: 1;
  }
}

.title-header.file-upload {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 20px;
  color: #4d4f5c;
  text-align: center;
}

.image-preview {
  width: 100%;
  margin-bottom: 20px;
  @include flex-center;

  img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: #f5f5f5;
  }
}

.button-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  button {
    background-color: $primary-color;
    color: white;
    border: none;
    padding: 10px;
    margin: 10px 0;
    font-size: 16px;
    border-radius: $border-radius;
    cursor: pointer;
    width: 90%;
    max-width: 300px;
    @include transition(background-color);
    text-transform: none;

    &:hover {
      background-color: $primary-hover-color;
    }

    &:disabled {
      background-color: #d3d3d3;
      cursor: not-allowed;
    }

    &.secondary-button {
      background-color: $secondary-color;
      color: $text-color;

      &:hover {
        background-color: $secondary-hover-color;
      }
    }
  }
}

.promt-button {
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  @include transition(background-color);

  &.step-by-step {
    background-color: #556990;
    color: #fff;

    &:hover {
      background-color: #49597a;
    }
  }

  &.questions {
    background-color: #edf0f4;
    color: $text-color;

    &:hover {
      background-color: #d9dce1;
    }
  }

  &.help-fix {
    background-color: $bg-color;
    color: $text-color;

    &:hover {
      background-color: $bg-hover-color;
    }
  }
}

.promt-input {
  width: 90%;
  padding: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: $border-radius;
  @include transition(border-color);

  &:focus {
    border-color: #4a5a8c;
    outline: none;
  }

  &.own-question {
    background-color: #f2f4f6;
  }
}

.image-preview-container {
  text-align: center;

  h1 {
    word-wrap: normal;
    width: 50%;
  }

  .button-group {
    input {
      text-align: center;
    }
  }
}

// Media queries
@media (max-height: 600px) {
  .sidebar.file-upload {
    max-height: 70vh;
  }
}

@media (max-height: 400px) {
  .sidebar.file-upload {
    max-height: 60vh;
  }
}
