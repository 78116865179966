.cancel-button-bulma {
  background-color: #506a93 !important;
  color: white !important;
  width: 150px !important;
  border-radius: 50px !important;
  border: none !important;
  cursor: pointer !important;
  text-align: center !important;
  padding: 5px 10px !important;
  font-size: 14px !important;
  transition: background-color 0.2s ease !important;
  margin-bottom: 5px;
  &:hover {
    background-color: #405883 !important;
  }
}

.cancel-button-label {
  font-family: 'Sofia Pro Soft', sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.cancel-button-wrapper {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 65px;
}

.input-field {
  margin-top: 10px;
}

.custom-border-radius .MuiOutlinedInput-root {
  border-radius: 8px;
}

.icon {
  color: gray;
  margin-right: 8px;
}

.textField {
  width: 100vw;
}

.cancel-button-container {
  margin-bottom: 10px;
}

.cancel-and-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-container {
  height: 150px;
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: relative;
  z-index: 10;
}

.input-form {
  width: 100%;
}

.item-divider {
  margin: 0px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.modal-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  cursor: pointer !important;
  margin-bottom: 5px;
}

.modal-button {
  min-width: 130px;
  margin-right: 0px;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  white-space: normal;
  border: 2px solid #000; /* Change color and size as needed */

  label {
    cursor: pointer !important;
  }
}

.button-content {
  display: flex;
  align-items: center;
}
