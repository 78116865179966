@import 'assets/styles/variables';

.PageCategory {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  .column {
    margin-bottom: 30px;
    @include touch {
      margin-bottom: 0;
    }
  }
  .item {
    @include list-item;
  }
  .no-default-list-items {
    margin-top: 40px;
    text-align: center;
    img {
      max-width: 500px;
      width: 100%;
      margin-top: 10px;
    }
  }
}
