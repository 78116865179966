@import 'assets/styles/variables';

.AppHero {
  background-color: $color-bg-header-start;
  border-bottom: 1px solid $color-border;
  height: 40vh;
  min-height: 380px;
  padding-bottom: 10vh;
  transition: all 0.35s ease-out;
  backface-visibility: hidden;
  &.compact {
    min-height: 0;
    height: 120px;
    padding-bottom: 0;
  }
  @include mobile {
    min-height: 0;
    height: auto;
    padding: 30px 0 0;
    &.compact {
      padding: 0;
    }
    .hero-body {
      padding-bottom: 2rem;
    }
    h1.title {
      margin-bottom: 0;
    }
  }
  @media screen and (max-height: 850px) {
    transition: none;
  }
  &.dictionary {
    background-color: $color-dictionary;
  }
  &.encyclopedia {
    background-color: $color-encyclopedia;
  }
  &.mediasearch {
    background-color: $color-mediasearch;
  }
  &.chatGpt {
    background-color: $color-chatGpt;
  }
  &.ukraine {
    @include mobile {
      height: 180px;
      .hero-body {
        padding-bottom: 15px;
      }
    }
    @media screen and (max-width: 413px) {
      .hero-body {
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
        .button {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}
