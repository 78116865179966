@import 'assets/styles/variables';
@import 'assets/styles/mixins';

.PageMultiSearch {
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  .column {
    margin-bottom: 30px;
    @include touch {
      margin-bottom: 0;
    }
  }
  .item {
    @include list-item;

    &.result-item {
      padding: 0;
      transform: translateY(0) scale(1);
      &:hover {
        transform: translateY(3px) scale(1.01);
        .image-container .image {
          transform: scale(1.03);
        }
      }
      .image-container {
        height: 230px;
        overflow: hidden;
        .image {
          transform: scale(1);
          transition: all 0.2s ease-in-out;
          height: 100%;
          background-size: cover;
          background-position: top;
        }
      }

      .content {
        position: relative;
        padding: 24px 40px 44px;
        margin-bottom: 0;
        &.no-image {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: center;
          align-items: center;
          justify-content: center;
          height: 100%;
          .source-container {
            transform: translateY(24px) translateX(-24px);
          }
          .extra {
            transform: translateY(24px);
          }
        }
        @include touch {
          padding: 24px 30px 40px;
        }
        .source-container {
          position: absolute;
          background-color: $color-bg-card;
          top: 0;
          right: 0;
          width: 40px;
          height: 40px;
          border: 1px solid $color-border;
          border-radius: 50%;
          transform: translateY(-50%) translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          .source {
            width: 24px;
            max-height: 24px;
          }
        }
        .extra {
          align-items: center;
          background-color: $color-bg-card;
          border-radius: 0 10px 10px 0;
          border: 1px solid $color-border;
          border-left: none;
          display: flex;
          justify-content: center;
          left: 0;
          padding: 0 9px;
          height: 26px;
          position: absolute;
          top: 0;
          transform: translateY(-50%);
          z-index: 9;
          &.left {
            .text {
              margin-left: -2px;
            }
          }
          &.right {
            right: 0;
            left: auto;
            border-radius: 10px 0 0 10px;
            border: 1px solid $color-border;
            border-right: 0;
          }
          .sr-icon-container {
            padding-right: 4px;
            display: flex;
            align-items: center;
            .info {
              height: 16px;
              transform: translateY(-1px);
              margin-right: 2px;
            }
            .toggle {
              font-size: 12px;
            }
          }
          .text {
            font-size: 15px;
            margin-top: -1px;
          }
        }
      }
    }

    .source-criticism-container {
      @include source-criticism-colors;
      position: absolute;
      transform: translateZ(0);
      bottom: 0px;
      right: 0px;
      width: 48px;
      height: 48px;
      border-top-left-radius: 100%;
      text-align: center;
      cursor: help;
      transition: all 0.2s ease-out;
      &:hover {
        width: 60px;
        .icon {
          opacity: 1;
        }
      }
      .icon {
        margin-top: 11px;
        font-size: 17px;
        opacity: 0.7;
        transition: all 0.2s ease-out;
      }
      .info {
        opacity: 0;
        visibility: hidden;
        margin-top: -4px;
        padding-right: 20px;
        line-height: 1.1em;
        font-size: 15px;
        transition: opacity 0.15s ease-out;
        @media screen and (max-width: 413px) {
          font-size: 14px;
        }
      }
      &.active {
        width: 100%;
        border-radius: 0;
        height: 48px;
        text-align: left;
        display: flex;
        align-items: center;
        .icon {
          margin: -2px 8px 0 30px;
          font-size: 20px;
          opacity: 1;
        }
        .info {
          opacity: 1;
          visibility: visible;
        }
      }
      &.transitioning {
        .info {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
