@import 'assets/styles/variables';

.PageLogin {
  height: 100vh;
  .logo {
    height: 46px;
    margin: -7px 0 0 14px;
    @include mobile {
      height: 31px;
      margin: -12px 0 0 10px;
    }
  }
  button .text {
    margin-top: -4px;
  }
  .flag {
    transform: translateY(35px);
    height: 28px;
    margin: 0 5px;
    cursor: pointer;
  }
  .login-error {
    background-color: $color-red;
    color: $color-text-on-dark;
    width: 50%;
    margin: 0 auto;
  }
  .close-login-error {
    float: right;
    right: -15px;
    top: -15px;
  }
}
