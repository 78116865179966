.flex-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 120px);
}

.chat-container {
  flex-grow: 1;
  width: 0;
  display: flex;
  flex-direction: column;
}
